/* Footer */
.footer {
    background-color: #252525;
    color: #ffffff;
    padding: 20px;
    margin-top: 50px;
}

.footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 600px;
    margin: 0 auto;
}

.social-icons a {
    color: #ffffff;
    font-size: 20px;
    margin-right: 10px;
}

.social-icons a:hover {
    color: #aaaaaa;
}

.social-icons a:last-child {
    margin-right: 0;
}

.social-icons a:focus {
    outline: none;
}

.social-icons svg {
    vertical-align: middle;
}

.copyright {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    margin-left: 20px;
}