/* Landing page container */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Header */

header img {
    height: 80px;
}

/* Main content */
h1 {
  font-size: 52px;
  margin-bottom: 20px;
  text-align: center; /* Center align the heading */
}

button {
  padding: 20px 30px;
  background-color: #007bff;
  color: #fff;
  font-weight: 900;
  border: none;
  border-radius: 4px;
  font-size: 25px;
  cursor: pointer;
  display: block; /* Set button to block element */
  margin: 0 auto; /* Center align the button */
  margin-bottom: 25px;
}

button:hover {
  background-color: #0056b3;
}

/* Footer */
footer {
  margin-top: auto;
  padding: 20px;
  background-color: #333;
}

ul {
  list-style: none;
  display: flex;
  justify-content: center;
}

li {
  margin: 0 10px;
}

a {
  color: #fff;
}

a:hover {
  color: #ccc;
}





@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .main {
    width: 100%;
  }
  .main img {
    height: 200px;
    width: 100%;
    display: block; /* Set button to block element */
    margin: 0 auto; /* Center align the button */
    border-radius: 5%;
  }
}

@media only screen and (min-width: 769px) {
  /* For laptop: */
  .main {
    width: 60%;
  }
  .main img {
    height: 250px;
    display: block; /* Set button to block element */
    margin: 0 auto; /* Center align the button */
    border-radius: 5%;
  }
}